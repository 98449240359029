import { SlotType } from '../slots';
import { FormOnlyProps } from './FormOnly';
import { FullImageFormProps } from './FullImageForm';
import { HalfImageFormProps } from './HalfImageForm';
import { HalfImageFramedProps } from './HalfImageFramed';
import { HalfImageFullBleedProps } from './HalfImageFullBleed';
import { HalfImageRoundedCornersProps } from './HalfImageRoundedCorners';
import { HalfVideoRoundedCornersProps } from './HalfVideoRoundedCorners';
import { NoImageProps } from './NoImage';

export type VariantTheme =
  | 'white'
  | 'foregroundSubtle'
  | 'brand'
  | 'foreground'
  | 'action'
  | 'accent1'
  | 'accent2'
  | 'transparent'
  | 'actionLight'
  | 'whiteLightButton'
  | 'brandLighten';
export type VariantSize = 'small' | 'medium' | 'large';
export type ImageVariant = 'halfImageFramed' | 'halfImageFullBleed' | 'halfImageRoundedCorners';
export type FormVariant = 'halfImageForm' | 'fullImageForm';
export type VideoVariant = 'halfVideoRoundedCorners';
export type VariantOrder = 'default' | 'imageAbove' | 'imageBelow';

export const VARIANT_THEMES = [
  'white',
  'foregroundSubtle',
  'brand',
  'foreground',
  'action',
  'accent1',
  'accent2',
  'transparent',
  'actionLight',
  'whiteLightButton',
  'brandLighten',
];
export const VARIANT_SIZES = ['small', 'medium', 'large'];
export const IMAGE_VARIANTS = ['halfImageFramed', 'halfImageFullBleed', 'halfImageRoundedCorners'];
export const FORM_VARIANTS = ['halfImageForm', 'fullImageForm'];
export const VIDEO_VARIANTS = ['halfVideoRoundedCorners'];
export const VARIANT_ORDERS = ['default', 'imageAbove', 'imageBelow'];

export const DEFAULT_VARIANT_THEME = 'white';
export const DEFAULT_VARIANT_SIZE = 'small';
export const DEFAULT_IMAGE_VARIANT = 'halfImageFullBleed';
export const DEFAULT_FORM_VARIANT = 'halfImageForm';
export const DEFAULT_VIDEO_VARIANT = 'halfVideoRoundedCorners';
export const DEFAULT_VARIANT_ORDER = 'default';

export interface BaseVariantProps {
  slots: Array<SlotType>;
  reverse?: boolean;
  reverseMobile?: boolean;
  theme?: VariantTheme;
  size?: VariantSize;
  order?: VariantOrder;
}

export type VariantProps =
  | NoImageProps
  | HalfImageFramedProps
  | HalfImageFullBleedProps
  | HalfImageFormProps
  | HalfImageRoundedCornersProps
  | HalfVideoRoundedCornersProps
  | FullImageFormProps
  | FormOnlyProps;

export const isImageVariant = (value: string): value is ImageVariant => IMAGE_VARIANTS.includes(value);
export const parseImageVariant = (value?: Nullable<string>): ImageVariant =>
  value && isImageVariant(value) ? value : DEFAULT_IMAGE_VARIANT;

export const isFormVariant = (value: string): value is FormVariant => FORM_VARIANTS.includes(value);
export const parseFormVariant = (value?: Nullable<string>): FormVariant =>
  value && isFormVariant(value) ? value : DEFAULT_FORM_VARIANT;

export const isVideoVariant = (value: string): value is VideoVariant => VIDEO_VARIANTS.includes(value);
export const parseVideoVariant = (value?: Nullable<string>): VideoVariant =>
  value && isVideoVariant(value) ? value : DEFAULT_VIDEO_VARIANT;

export const isVariantTheme = (value: string): value is VariantTheme => VARIANT_THEMES.includes(value);
export const parseVariantTheme = (value?: Nullable<string>): VariantTheme =>
  value && isVariantTheme(value) ? value : DEFAULT_VARIANT_THEME;

export const isVariantSize = (value: string): value is VariantSize => VARIANT_SIZES.includes(value);
export const parseVariantSize = (value?: Nullable<string>): VariantSize =>
  value && isVariantSize(value) ? value : DEFAULT_VARIANT_SIZE;

export const isVariantOrder = (value: string): value is VariantOrder => VARIANT_ORDERS.includes(value);
export const parseVariantOrder = (value?: Nullable<string>): VariantOrder =>
  value && isVariantOrder(value) ? value : DEFAULT_VARIANT_ORDER;
