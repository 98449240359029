import React, { useContext } from 'react';
import cc from 'classcat';

import ImageOrPlaceholder from '../../common/ImageOrPlaceholder';
import Slots from '../../slots';
import { ThemeContext } from '../..';
import { BaseVariantProps, DEFAULT_VARIANT_ORDER } from '..';

import styles from './styles.module.scss';

export interface HalfImageRoundedCornersProps extends BaseVariantProps {
  image?: Nullable<AssetFragment>;
  overflowStyle?: boolean;
  variant: 'halfImageRoundedCorners';
}

export default function HalfImageRoundedCorners({
  slots,
  image,
  reverse,
  reverseMobile,
  overflowStyle,
}: HalfImageRoundedCornersProps) {
  const { theme, size, order } = useContext(ThemeContext);
  return (
    <div
      className={cc({
        [styles.halfImageRoundedCorners]: true,
        [styles.reverse]: reverse,
        [styles.reverseMobile]: reverseMobile,
        [styles.imageOverflow]: overflowStyle,
        [styles[theme]]: true,
        [styles[size]]: true,
        [styles[order]]: order !== DEFAULT_VARIANT_ORDER,
      })}
    >
      <div className={styles.slotsWrapper}>
        <Slots slots={slots} />
      </div>
      <div className={styles.imageContainer}>
        <ImageOrPlaceholder image={image} className={styles.image} />
      </div>
    </div>
  );
}
