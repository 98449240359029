import React, { useContext } from 'react';
import cc from 'classcat';

import FormBlock, { FormBlockProps } from '../../common/FormBlock';
import ImageOrPlaceholder from '../../common/ImageOrPlaceholder';
import Slots from '../../slots';
import { ThemeContext } from '../..';
import { BaseVariantProps } from '..';

import styles from './styles.module.scss';

export interface HalfImageFormProps extends BaseVariantProps {
  image?: Nullable<AssetFragment>;
  form: FormBlockProps;
  variant: 'halfImageForm';
}

export default function HalfImageForm({ slots, image, reverse, reverseMobile, form }: HalfImageFormProps) {
  const { theme, size } = useContext(ThemeContext);
  return (
    <div
      className={cc({
        [styles.halfImageForm]: true,
        [styles.reverse]: reverse,
        [styles.reverseMobile]: reverseMobile,
        [styles[theme]]: true,
        [styles[size]]: true,
      })}
    >
      <div className={styles.slotsWrapper}>
        <Slots slots={slots} />
      </div>
      <div className={styles.side}>
        <div className={styles.formWrapper}>
          <FormBlock {...form} />
        </div>
        <div className={styles.imageWrapper}>
          <ImageOrPlaceholder image={image} className={styles.image} />
        </div>
      </div>
    </div>
  );
}
