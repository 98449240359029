import React, { useContext } from 'react';
import cc from 'classcat';

import { ThemeContext } from '../..';

import styles from './styles.module.scss';

export interface SlotListProps {
  list: string;
  type: 'slotList';
}

export default function SlotList({ list }: SlotListProps) {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={cc([styles.slotList, styles[theme]])}>
      <div dangerouslySetInnerHTML={{ __html: list }} />
    </div>
  );
}
