import React, { useState } from 'react';
import cc from 'classcat';
import ReactPlayer from 'react-player';

import Button from '@/components/Button';

import PauseIcon from '@/public/images/icons/tabler-icons/player-pause.svg';
import PlayIcon from '@/public/images/icons/tabler-icons/player-play.svg';

import styles from './styles.module.scss';

interface Props {
  videoSrc: string;
  autoplayVideo?: boolean;
  muted?: boolean;
}

const Video = ({ videoSrc, autoplayVideo = false, muted }: Props) => {
  const [playState, setPlayState] = useState(autoplayVideo);

  const toggleVideoPlayState = () => {
    setPlayState(!playState);
  };

  return (
    <div className={styles.videoWrapper}>
      <ReactPlayer
        url={videoSrc}
        loop
        playing={playState}
        muted={muted || autoplayVideo}
        className={styles.image}
        // unset the hardcoded width & height
        width=""
        height=""
      />
      <Button
        styleOverride={cc({
          [styles.buttonOverride]: true,
          [styles.paused]: !playState,
        })}
        onClick={toggleVideoPlayState}
        size="large"
        type="brand"
      >
        {playState ? <PauseIcon /> : <PlayIcon />}
      </Button>
    </div>
  );
};

export default Video;
