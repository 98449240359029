import React, { useContext } from 'react';
import cc from 'classcat';

import ImageOrPlaceholder from '../../common/ImageOrPlaceholder';
import Slots from '../../slots';
import { ThemeContext } from '../..';
import { BaseVariantProps, DEFAULT_VARIANT_ORDER } from '..';

import styles from './styles.module.scss';

export interface HalfImageFramedProps extends BaseVariantProps {
  image?: Nullable<AssetFragment>;
  overflowStyle?: boolean;
  variant: 'halfImageFramed';
}

export default function HalfImageFramed({ slots, image, reverse, reverseMobile, overflowStyle }: HalfImageFramedProps) {
  const { theme, size, order } = useContext(ThemeContext);
  return (
    <div
      className={cc({
        [styles.halfImageFramed]: true,
        [styles.reverse]: reverse,
        [styles.reverseMobile]: reverseMobile,
        [styles.imageOverflow]: overflowStyle,
        [styles[theme]]: true,
        [styles[size]]: true,
        [styles[order]]: order !== DEFAULT_VARIANT_ORDER,
      })}
    >
      <Slots slots={slots} />
      <ImageOrPlaceholder image={image} className={styles.image} />
    </div>
  );
}
