import React, { useContext } from 'react';
import cc from 'classcat';

import { ThemeContext } from '../..';

import styles from './styles.module.scss';

interface SlotHeadingProps {
  heading: string;
  type: 'slotHeading';
}

export default function SlotHeading({ heading }: SlotHeadingProps) {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={cc([styles.slotHeading, styles[theme]])}>
      <div dangerouslySetInnerHTML={{ __html: heading }} />
    </div>
  );
}

export type { SlotHeadingProps };
