import React, { useContext } from 'react';
import cc from 'classcat';

import { ThemeContext } from '../..';

import styles from './styles.module.scss';

interface SlotParagraphProps {
  paragraph: string;
  type: 'slotParagraph';
}

export default function SlotParagraph({ paragraph }: SlotParagraphProps) {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={cc([styles.slotParagraph, styles[theme]])}>
      <div dangerouslySetInnerHTML={{ __html: paragraph }} />
    </div>
  );
}

export type { SlotParagraphProps };
