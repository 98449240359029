import React, { useContext } from 'react';
import cc from 'classcat';

import Slots from '../../slots';
import { ThemeContext } from '../..';
import { BaseVariantProps } from '..';

import styles from './styles.module.scss';

export interface NoImageProps extends BaseVariantProps {
  noPadding?: boolean;
  variant: 'noImage';
}

export default function NoImage({ slots, noPadding }: NoImageProps) {
  const { theme, size } = useContext(ThemeContext);
  return (
    <div
      className={cc({
        [styles.noImage]: true,
        [styles.noPadding]: !!noPadding,
        [styles[theme]]: true,
        [styles[size]]: true,
      })}
    >
      <Slots slots={slots} alignment="center" />
    </div>
  );
}
