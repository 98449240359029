import React, { useContext } from 'react';
import cc from 'classcat';

import FormBlock, { FormBlockProps } from '../../common/FormBlock';
import ImageOrPlaceholder from '../../common/ImageOrPlaceholder';
import { ThemeContext } from '../..';
import { BaseVariantProps } from '..';

import styles from './styles.module.scss';

export interface FormOnlyProps extends BaseVariantProps {
  image: Nullable<AssetFragment>;
  form: FormBlockProps;
  variant: 'formOnly';
}

export default function FormOnly({ reverse, reverseMobile, image, form }: FormOnlyProps) {
  const { theme, size } = useContext(ThemeContext);
  return (
    <div
      className={cc({
        [styles.formOnly]: true,
        [styles.reverse]: reverse,
        [styles.reverseMobile]: reverseMobile,
        [styles[size]]: true,
      })}
    >
      <div className={styles.slotsWrapper}>
        <div className={styles.imageWrapper}>
          <ImageOrPlaceholder image={image} className={styles.image} />
        </div>
      </div>
      <div
        className={cc({
          [styles.side]: true,
          [styles[theme]]: true,
        })}
      >
        <div className={styles.formWrapper}>
          <FormBlock {...form} />
        </div>
      </div>
    </div>
  );
}
