import React from 'react';
import cc from 'classcat';

import SlotButtons, { SlotButtonsProps } from './Buttons';
import SlotDynamicButton, { SlotDynamicButtonProps } from './DynamicButton';
import SlotHeading, { SlotHeadingProps } from './Heading';
import SlotList, { SlotListProps } from './List';
import SlotParagraph, { SlotParagraphProps } from './Paragraph';

import styles from './styles.module.scss';

export type SlotType =
  | SlotHeadingProps
  | SlotButtonsProps
  | SlotParagraphProps
  | SlotListProps
  | SlotDynamicButtonProps;

interface SlotsProps {
  slots: Array<SlotType>;
  alignment?: 'left' | 'center' | 'right';
}

export default function Slots({ slots, alignment = 'left' }: SlotsProps) {
  return (
    <div className={cc({ [styles.slots]: true, [styles[alignment]]: true })}>
      {slots.map((slot, index) => (
        <Slot slot={slot} key={`${slot.type}-${index}`} />
      ))}
    </div>
  );
}

interface SlotProps {
  slot: SlotType;
}

export function Slot({ slot }: SlotProps) {
  switch (slot.type) {
    case 'slotButtons':
      return <SlotButtons {...slot} />;
    case 'slotHeading':
      return <SlotHeading {...slot} />;
    case 'slotParagraph':
      return <SlotParagraph {...slot} />;
    case 'slotList':
      return <SlotList {...slot} />;
    case 'slotDynamicButton':
      return <SlotDynamicButton {...slot} />;
    /* istanbul ignore next */
    default:
      return null;
  }
}
