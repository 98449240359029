import React, { useContext } from 'react';
import { useQuery } from 'urql';

import Button from '@/components/Button';
import useStore from '@/hooks/useStore';
import { getDealerFilterString } from '@/lib/getServerSideProps';
import stockQuery from '@/lib/Stock/stock.graphql';
import StockViewCount from '@/lib/StockViewCount';
import { SSRContext } from '@/typings/SSRContext';

import { VariantTheme } from '../../variants';
import { ThemeContext } from '../..';

import styles from './styles.module.scss';

export interface SlotDynamicButtonProps {
  parentComponent?: string;
  button: SubModuleContentDynamicButtonFragment;
  type: 'slotDynamicButton';
}

export type slotDynamicButtonVariants = 'shopTotalCars';

export default function SlotDynamicButton({ button }: SlotDynamicButtonProps) {
  // Switch case render variants
  const { dealershipsArray } = useStore('dealershipsMap');
  const { isUsedCarPlatform } = useStore('globals');
  switch (button.contentModuleDynamicButtonVariants as slotDynamicButtonVariants) {
    case 'shopTotalCars':
      const dealerFilterString = isUsedCarPlatform ? getDealerFilterString(dealershipsArray) : '';

      return <VariantShopTotalCars filters={dealerFilterString} />;
    default:
      return null;
  }
}

SlotDynamicButton.prefetch = async (
  { urqlClient, nextCtx: { locale }, stores: { globals } }: SSRContext,
  dynamicButtonVariant: slotDynamicButtonVariants,
) => {
  switch (dynamicButtonVariant) {
    case 'shopTotalCars':
      const filterString = globals.isUsedCarPlatform
        ? (await StockViewCount.getDealerIdFilters(urqlClient, locale!)) ?? ''
        : '';
      await urqlClient
        ?.query<StockQuery>(stockQuery, {
          filters: filterString,
          limit: 1,
        })
        .toPromise();
    default:
  }
};

//Variants
interface Props {
  type?: Maybe<string>;
  filters?: string;
}

interface ButtonStyleMap {
  button: ButtonType;
}

const themeButtonStyleMap: Record<VariantTheme, ButtonStyleMap> = {
  white: {
    button: 'button',
  },
  foregroundSubtle: {
    button: 'button',
  },
  brand: {
    button: 'on-brand',
  },
  foreground: {
    button: 'on-foreground',
  },
  action: {
    button: 'on-action',
  },
  accent1: {
    button: 'on-accent-1',
  },
  accent2: {
    button: 'on-accent-2',
  },
  transparent: {
    button: 'button',
  },
  actionLight: {
    button: 'button',
  },
  whiteLightButton: {
    button: 'action-light',
  },
  brandLighten: {
    button: 'button',
  },
};

const VariantShopTotalCars = ({ filters = '' }: Props) => {
  const { theme } = useContext(ThemeContext);

  const [{ data: stockQueryResult }] = useQuery<StockQuery>({
    query: stockQuery,
    variables: {
      filters,
      limit: 1,
    },
  });
  const total = stockQueryResult?.stockList?.total ?? VariantShopTotalCars.defaultTotal;

  return (
    <div className={styles.slotButtons}>
      <Button
        href={VariantShopTotalCars.url}
        type={themeButtonStyleMap[theme].button}
        styleOverride={styles.shopTotalCars}
      >
        Shop {total.toLocaleString()} cars
      </Button>
    </div>
  );
};

VariantShopTotalCars.defaultTotal = '3,000+';
VariantShopTotalCars.url = '/cars';
