import React, { useContext } from 'react';
import cc from 'classcat';

import Video from '@/components/Video';

import Slots from '../../slots';
import { ThemeContext } from '../..';
import { BaseVariantProps, DEFAULT_VARIANT_ORDER } from '..';

import styles from './styles.module.scss';

export interface HalfVideoRoundedCornersProps extends BaseVariantProps {
  videoUrl: string;
  autoplayVideo?: boolean;
  variant: 'halfVideoRoundedCorners';
}

export default function HalfVideoRoundedCorners({
  slots,
  videoUrl,
  reverse,
  reverseMobile,
  autoplayVideo,
}: HalfVideoRoundedCornersProps) {
  const { theme, size, order } = useContext(ThemeContext);

  return (
    <div
      className={cc({
        [styles.halfVideoRoundedCorners]: true,
        [styles.reverse]: reverse,
        [styles.reverseMobile]: reverseMobile,
        [styles[theme]]: true,
        [styles[size]]: true,
        [styles[order]]: order !== DEFAULT_VARIANT_ORDER,
      })}
    >
      <div className={styles.slotsWrapper}>
        <Slots slots={slots} />
      </div>
      <div className={styles.videoContainer}>
        <Video videoSrc={videoUrl} autoplayVideo={autoplayVideo} />
      </div>
    </div>
  );
}
