import React, { useContext } from 'react';
import cc from 'classcat';

import ImageOrPlaceholder from '../../common/ImageOrPlaceholder';
import Slots from '../../slots';
import { ThemeContext } from '../..';
import { BaseVariantProps, DEFAULT_VARIANT_ORDER } from '..';

import styles from './styles.module.scss';

export interface HalfImageFullBleedProps extends BaseVariantProps {
  image?: Nullable<AssetFragment>;
  variant: 'halfImageFullBleed';
}

export default function HalfImageFullBleed({ slots, image, reverse, reverseMobile }: HalfImageFullBleedProps) {
  const { theme, size, order } = useContext(ThemeContext);
  return (
    <div
      className={cc({
        [styles.halfImageFullBleed]: true,
        [styles.reverse]: reverse,
        [styles.reverseMobile]: reverseMobile,
        [styles[theme]]: true,
        [styles[size]]: true,
        [styles[order]]: order !== DEFAULT_VARIANT_ORDER,
      })}
    >
      <div className={styles.slotsWrapper}>
        <Slots slots={slots} />
      </div>
      <ImageOrPlaceholder image={image} className={styles.image} />
    </div>
  );
}
