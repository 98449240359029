import React from 'react';
import cc from 'classcat';

import Image from '@/components/Image';

import PlaceholderIcon from '@/images/icons/placeholder.svg';

import styles from './styles.module.scss';

interface Props {
  image?: Nullable<AssetFragment>;
  className?: string;
}

export default function ImageOrPlaceholder({ image, className }: Props) {
  return (
    <div className={styles.imageOrPlaceholder}>
      {image ? (
        <Image data-testid="contentModuleImage" {...image} className={className} />
      ) : (
        <div data-testid="placeholder" className={cc([styles.placeholder, className])}>
          <PlaceholderIcon />
        </div>
      )}
    </div>
  );
}
