import React, { useContext } from 'react';

import Button from '@/components/Button';
import { getIcon } from '@/lib/IconHelper';
import { isGenericLinkText } from '@/lib/Link';

import { VariantTheme } from '../../variants';
import { ThemeContext } from '../..';

import styles from './styles.module.scss';

export interface SlotButton {
  link: CustomLinkFieldFragment;
  icon?: IconFragment;
}

export interface SlotButtonsProps {
  button1: SlotButton;
  button2?: SlotButton;
  type: 'slotButtons';
}

interface ButtonStyleMap {
  button1: ButtonType;
  button2: ButtonType;
}

const themeButtonStyleMap: Record<VariantTheme, ButtonStyleMap> = {
  white: {
    button1: 'button',
    button2: 'blue-transparent',
  },
  foregroundSubtle: {
    button1: 'button',
    button2: 'blue-transparent',
  },
  brand: {
    button1: 'on-brand',
    button2: 'on-brand-transparent',
  },
  foreground: {
    button1: 'on-foreground',
    button2: 'on-foreground-transparent',
  },
  action: {
    button1: 'on-action',
    button2: 'on-action-transparent',
  },
  accent1: {
    button1: 'on-accent-1',
    button2: 'on-accent-1-transparent',
  },
  accent2: {
    button1: 'on-accent-2',
    button2: 'on-accent-2-transparent',
  },
  transparent: {
    button1: 'button',
    button2: 'blue-transparent',
  },
  actionLight: {
    button1: 'button',
    button2: 'blue-transparent',
  },
  whiteLightButton: {
    button1: 'action-light',
    button2: 'action-light-transparent',
  },
  brandLighten: {
    button1: 'button',
    button2: 'blue-transparent',
  },
};

export default function SlotButtons({ button1, button2 }: SlotButtonsProps) {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={styles.slotButtons}>
      <Button
        href={button1.link.url!}
        target={button1.link.target || undefined}
        type={themeButtonStyleMap[theme].button1}
      >
        {button1.link.text}
        {button1?.icon && getIcon(button1.icon)}
        {button1.link.text && isGenericLinkText(button1.link.text) && (
          <span className={styles.screenReaderText}>details</span>
        )}
      </Button>
      {button2 && (
        <Button
          href={button2.link.url!}
          target={button1.link.target || undefined}
          type={themeButtonStyleMap[theme].button2}
        >
          {button2.link.text}
          {button2.icon && getIcon(button2.icon)}
          {button2.link.text && isGenericLinkText(button2.link.text) && (
            <span className={styles.screenReaderText}>details</span>
          )}
        </Button>
      )}
    </div>
  );
}
