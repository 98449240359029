import React, { useContext } from 'react';

import Button from '@/components/Button';
import { getIcon } from '@/lib/IconHelper';

import { VariantTheme } from '../../variants';
import { ThemeContext } from '../..';

import styles from './styles.module.scss';

export interface FormBlockProps {
  heading: string;
  paragraph?: string;
  button: CustomLinkFieldFragment;
  icon?: Maybe<IconFragment>;
}

const themeButtonStyleMap: Record<VariantTheme, ButtonType> = {
  white: 'button',
  foregroundSubtle: 'button',
  brand: 'button',
  foreground: 'button',
  action: 'button',
  accent1: 'button',
  accent2: 'button',
  transparent: 'button',
  actionLight: 'button',
  whiteLightButton: 'action-light',
  brandLighten: 'button',
};

export default function FormBlock({ heading, paragraph, button, icon }: FormBlockProps) {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={styles.formBlock}>
      <div className={styles.heading} dangerouslySetInnerHTML={{ __html: heading }} />
      {paragraph && <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: paragraph }} />}
      <Button type={themeButtonStyleMap[theme]} href={button.url!} target={button.target || undefined}>
        {button.text}
        {icon && getIcon(icon)}
      </Button>
    </div>
  );
}
