import React, { useMemo } from 'react';

import { SlotButton, SlotButtonsProps } from './slots/Buttons';
import { SlotDynamicButtonProps } from './slots/DynamicButton';
import { SlotHeadingProps } from './slots/Heading';
import { SlotListProps } from './slots/List';
import { SlotParagraphProps } from './slots/Paragraph';
import {
  DEFAULT_VARIANT_ORDER,
  DEFAULT_VARIANT_SIZE,
  DEFAULT_VARIANT_THEME,
  VariantOrder,
  VariantProps,
  VariantSize,
  VariantTheme,
} from './variants';
import FormOnly from './variants/FormOnly';
import FullImageForm from './variants/FullImageForm';
import HalfImageForm from './variants/HalfImageForm';
import HalfImageFramed from './variants/HalfImageFramed';
import HalfImageFullBleed from './variants/HalfImageFullBleed';
import HalfImageRoundedCorners from './variants/HalfImageRoundedCorners';
import HalfVideoRoundedCorners from './variants/HalfVideoRoundedCorners';
import NoImage from './variants/NoImage';

interface ThemeContextType {
  theme: VariantTheme;
  size: VariantSize;
  order: VariantOrder;
}

export const ThemeContext = React.createContext<ThemeContextType>({
  theme: DEFAULT_VARIANT_THEME,
  size: DEFAULT_VARIANT_SIZE,
  order: DEFAULT_VARIANT_ORDER,
});

export default function ContentModule(props: VariantProps) {
  const variant = useMemo(() => {
    switch (props.variant) {
      case 'noImage':
        return <NoImage {...props} />;
      case 'halfImageFramed':
        return <HalfImageFramed {...props} />;
      case 'halfImageFullBleed':
        return <HalfImageFullBleed {...props} />;
      case 'halfImageForm':
        return <HalfImageForm {...props} />;
      case 'halfImageRoundedCorners':
        return <HalfImageRoundedCorners {...props} />;
      case 'halfVideoRoundedCorners':
        return <HalfVideoRoundedCorners {...props} />;
      case 'fullImageForm':
        return <FullImageForm {...props} />;
      case 'formOnly':
        return <FormOnly {...props} />;
      /* istanbul ignore next */
      default:
        return null;
    }
  }, [props]);

  return (
    <ThemeContext.Provider
      value={{
        theme: props.theme || DEFAULT_VARIANT_THEME,
        size: props.size || DEFAULT_VARIANT_SIZE,
        order: props.order || DEFAULT_VARIANT_ORDER,
      }}
    >
      {variant}
    </ThemeContext.Provider>
  );
}

export class Slots {
  public static Heading = (heading: string): SlotHeadingProps => ({ heading, type: 'slotHeading' });
  public static Paragraph = (paragraph: string): SlotParagraphProps => ({ paragraph, type: 'slotParagraph' });
  public static Buttons = (button1: SlotButton, button2?: SlotButton): SlotButtonsProps => ({
    button1,
    button2,
    type: 'slotButtons',
  });
  public static List = (list: string): SlotListProps => ({ list, type: 'slotList' });
  public static DynamicButton = (button: SubModuleContentDynamicButtonFragment): SlotDynamicButtonProps => ({
    button,
    type: 'slotDynamicButton',
  });
}
