import Cookies from 'js-cookie';
import { Client } from 'urql';

import GraphQLError from '@/errors/GraphQLError';
import StockViewCountsQuery from '@/graphql/queries/stockViewCountsQuery.graphql';
import CSRF from '@/lib/Csrf';
import Log from '@/lib/Log';
import stockQuery from '@/lib/Stock/stock.graphql';
import { Filter } from '@/models';
import dealershipsMapQuery from '@/stores/DealershipsMap/dealershipsMap.graphql';

import { getDealerIdFromInventorySource } from './DealershipHelper';
import { extractItems } from './GraphQLHelper';
import SendRequest from './SendRequest';

export default class StockViewCount {
  private static COOKIE_NAME = 'StockViewCount';

  public static async addCount(stockNo: string, headers: Record<string, string>) {
    const response = await this.submit(stockNo, '/stock-view-count/add', true, headers);
    return response;
  }

  public static async getCount(stockNo: string, headers: Record<string, string>) {
    const response = await this.submit(stockNo, '/stock-view-count/get', false, headers);
    return response;
  }

  /**
   * Get most viewed vehicles from today. If less are available then the requested amount,
   *  fill with stock.
   * @param urqlClient
   * @param amount amount of vehicles wanted
   * @param site site locale
   * @returns
   */
  public static async getTodaysVehicles(urqlClient: Client, amount: number, site: string): Promise<StockItem[]> {
    const counts = await this.getCounts(urqlClient, new Date(Date.now()), amount, site);

    // for each stockNo build a filter to query
    const filters: Filter<string>[] = [];
    counts.forEach((f) => f && f.stockNo && filters.push(new Filter('stockNo', f.stockNo)));

    let vehicles: StockItem[] = [];
    // get stock from viewcount
    if (filters.length) {
      const { data, operation, error } = await urqlClient
        .query<StockQuery>(stockQuery, {
          filters: Filter.toQueryString(filters),
        })
        .toPromise();

      if (error) {
        Log.error(`Failed to get todays vehicles`, { operation, error });
      }

      // Sort vehicles by count and set
      vehicles = this.extractVehicleFromStockList(data).sort((a, b) =>
        (counts.find((c) => c.stockNo === a.stockNo)?.count ?? 1) >
        (counts.find((c) => c.stockNo === b.stockNo)?.count ?? 1)
          ? -1
          : 1,
      );
    }

    // if not enough stock has been viewed; fill with normal stock
    if (vehicles.length < amount) {
      const remainingVehicles = await this.getRemainingVehicles(urqlClient, amount - vehicles.length, site);
      vehicles = [...vehicles, ...remainingVehicles];
    }

    return vehicles;
  }

  /**
   * Gets dealerID filters using locale.
   * Emulates the dealershipMapStore graphql call to avoid duplicate graphQL calls.
   * @param urqlClient
   * @param locale
   * @returns string | null on error
   */
  public static async getDealerIdFilters(urqlClient: Client, locale: string): Promise<Nullable<string>> {
    const { data, operation, error } = await urqlClient
      .query<DealershipsMapQuery>(dealershipsMapQuery, {
        site: locale,
      })
      .toPromise();
    const childLocations = extractItems<ChildDealershipFragment>(data?.locations);

    if (error || !childLocations) {
      Log.error('DealershipsMapQuery failed', {
        childLocations,
        operation,
        error,
      });
      return null;
    }

    // there no locaitons active on site (eg: used cars sites)
    if (childLocations.length === 0) {
      return null;
    }

    // Create dealerID filters based on childLocation dealerIDs
    const dealerFilters: Filter<string>[] = [];
    childLocations?.forEach((cl) => {
      const dealerId = getDealerIdFromInventorySource(cl.inventorySource);
      if (dealerId) {
        dealerFilters.push(new Filter('dealerID', dealerId));
      }
    });

    if (!dealerFilters.length) {
      Log.error('Unable to get any dealer filters from childLocations', {
        childLocations,
      });
      return null;
    }

    return Filter.toQueryString(dealerFilters);
  }

  /**
   * @deprecated Vehicle object is now StockItem type; should use extractItems<StockItem> from [ GraphqlHelper ]( ./GraphQLHelper.ts )
   */
  private static extractVehicleFromStockList(data?: StockQuery | undefined): StockItem[] {
    if (data && data.stockList) {
      return data.stockList.stock.filter((v) => v).map((v) => v as StockItem);
    }

    return [];
  }

  private static async getCounts(
    urqlClient: Client,
    date: Date,
    limit: number,
    site?: string,
  ): Promise<StockViewCountsFragment[]> {
    // Set to correct format
    const month = date.toLocaleDateString('en-AU').split('/').join('-');

    const { data, operation, error } = await urqlClient
      .query<StockViewCountsQuery, StockViewCountsQueryVariables>(StockViewCountsQuery, {
        date: month,
        limit: limit,
        site: site,
      })
      .toPromise();

    if (!!error) {
      Log.exception(new GraphQLError(error, operation), { operation, error });
    }

    return extractItems<StockViewCountsFragment>(data?.stockViewCounts) ?? [];
  }

  private static async getRemainingVehicles(urqlClient: Client, amount: number, site: string) {
    const dealerFilters = await this.getDealerIdFilters(urqlClient, site);
    if (!dealerFilters) return [];

    const {
      data: stockData,
      operation: stockOperation,
      error: stockError,
    } = await urqlClient
      .query<StockQuery, StockQueryVariables>(stockQuery, {
        limit: amount,
        filters: dealerFilters,
      })
      .toPromise();

    if (stockError) {
      Log.error('StockQuery failed', { stockOperation, stockError });
    }

    return this.extractVehicleFromStockList(stockData);
  }

  private static async submit(stockNo: string, path: string, setCookie = false, headers: Record<string, string>) {
    const formData = new FormData();
    const id = Cookies.get(this.COOKIE_NAME);

    formData.append('stockNo', stockNo);
    formData.append('sessionId', id || '');

    const csrfToken = headers.csrf || (await CSRF.getCsrf());
    formData.append('CRAFT_CSRF_TOKEN', csrfToken);

    try {
      const response = await SendRequest<FormData, { sessionId: string } | { error: string }>({
        path,
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': csrfToken,
        },
        formBody: formData,
      });

      if (response) {
        if ('error' in response) {
          Log.error('StockViewCount: request returned error', {
            stockNo,
            path,
            error: response.error,
          });
        }

        if (setCookie && 'sessionId' in response) {
          const now = new Date();
          // Set cookie to expire +1 year from now
          // note that this will be limited on a browser-level
          Cookies.set(this.COOKIE_NAME, response.sessionId, {
            expires: new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()),
            path: '/',
          });
          return response;
        }
      }

      return false;
    } catch (error) {
      Log.exception(error, {
        stockNo,
        path,
      });
      return false;
    }
  }
}
